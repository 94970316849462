'use client';
import Img from '@/components/atom/img';
import Link from 'next/link';
import React from 'react';
import { routePaths } from '@/routes/routePaths';
import { formatQuantityText } from '@/utils/newHelpers';
import { fShortenNumber } from '@/utils/format-number';

interface CommunityItemProps {
  imageUrl: string;
  title: string;
  membersCount: number;
  slug: string;
  isOwner: boolean;
  isJoined: boolean;
}

const CommunityItem: React.FC<CommunityItemProps> = ({
  imageUrl,
  title,
  membersCount,
  slug,
  isOwner,
  isJoined,
}) => {
  return (
    <div className="flex items-center justify-between p-2">
      <div className="flex items-center">
        <Img
          src={imageUrl || ''}
          alt={title}
          className="w-10 h-10 border-1 border-white shadow-img rounded-full"
          width={40}
          height={40}
        />
        <div className="ml-3">
          <h4 className="text-xs font-semibold text-gray-brand7">{title}</h4>
          {/* <p className="text-xs text-gray-brand5">{formatQuantityText(membersCount, `member`)}</p> */}
          <p className="text-xs text-gray-brand5">
            {membersCount ? fShortenNumber(membersCount) : 0}
            <span className="text-xs text-gray-brand5">members</span>
          </p>
        </div>
      </div>
      <Link
        className="text-purple-brand text-sm font-medium"
        href={`${routePaths.communityDetail}/${slug}`}
      >
        {isOwner || isJoined ? 'View' : 'Join'}
      </Link>
    </div>
  );
};

export default CommunityItem;
